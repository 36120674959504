import classNames from 'classnames'
import React from 'react'
import {Icon} from '../icons/Icon';
import type {ReactNode} from 'react';


export interface AvatarProps {
  alt?: string;
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode;
}

const AvatarContent = ({
  alt,
  size,
  children
}: AvatarProps) => {
  if (alt) return <>{alt[0]}</>
  if (children) return <>{children}</>

  return <Icon icon="user" size={size} />
}

const Avatar = ({alt, size = 'sm', children}: AvatarProps) => {

  const hasProps = !!alt || !!children

  const wrapperClassNames = classNames(
    'bg-timber-navy-400 rounded-full flex items-center justify-center fill-timber-neutral-100 text-timber-neutral-100 font-titillium font-semibold overflow-hidden uppercase',
    {
      'w-6 h-6 text-sm': size === 'sm',
      'w-8 h-8 text-lg': size === 'md',
      'w-10 h-10 text-2xl': size === 'lg',
      'p-[5px]': size === 'sm' && !hasProps,
      'p-[7px]': size === 'md' && !hasProps,
      'p-[8px]': size === 'lg' && !hasProps
    }
  )

  return (
    <div className={wrapperClassNames}>
      <AvatarContent {...{alt, size, children}} />
    </div>
  )
}

export default Avatar
