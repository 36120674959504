import * as React from 'react'

interface LayoutProps {
    id?: string;
    className?: string;
    style?: React.CSSProperties;
    headerTheme?: string;
    children?: React.ReactNode;
}

const BaseLayout = ({id, className, style, children}: LayoutProps) => {
  return (
    <div id={id} style={style} className={`bg-[#F3F7FB] min-h-screen ${className || ''}`} >
      {children}
    </div>
  )
}

export default BaseLayout
