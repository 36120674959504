import {useQuery} from '@tanstack/react-query';
import {authenticatedRequest} from '../../api/client';
import type {OrgUser} from './types';

export const useValidateUser = () => {
  const QUERY_VALIDATE_USER = ['validateUser']
  return useQuery<OrgUser, Error>(QUERY_VALIDATE_USER, () => validateUser(), {refetchOnWindowFocus: false})
}

const validateUser = async (): Promise<OrgUser> => {
  const url = `/user/validate`
  const {data} = await authenticatedRequest(url, 'GET')
  return data
}
