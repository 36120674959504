import type {Address} from '../features/warehouse/types'

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const renderAddress = (address: Address) => {
    if (address.address) {
        return address.address
    }

    const {city, street, county, countryCode} = address
    return `${city}, ${street}, ${county}, ${countryCode}`
}

export const generate3randomLetters = () => {
    const letters = 'abcdefghijklmnopqrstuv'
    let result = ''
    for (let i = 0; i < 3; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length))
    }
    return result
}

export const generate3randomNumbers = () => {
    let result = ''
    for (let i = 0; i < 3; i++) {
        result += Math.floor(Math.random() * 10)
    }
    return result
}
