import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom/client';
import {Toaster} from 'react-hot-toast';
import {BrowserRouter as Router} from 'react-router-dom'
import 'reactjs-popup/dist/index.css';
import Hotjar from '@hotjar/browser'
import App from './App';
import './assets/datepicker.css';
import {AuthenticationContext} from './contexts/AuthenticationContext';
import './index.css';
import {SidebarContext} from './contexts/SidebarContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_VERSION) {
  const hotJarId = Number(process.env.REACT_APP_HOTJAR_ID) || 0
  const hotJarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION) || 6

  Hotjar.init(hotJarId, hotJarVersion)
  console.log('Hotjar initialized', hotJarId, hotJarVersion)
} else {
  console.log('Hotjar not initialized')
}

export const queryClient = new QueryClient()

root.render(
  <SidebarContext>
    <QueryClientProvider client={queryClient}>
      <AuthenticationContext>
        <Router>
          <App />
          <Toaster />
        </Router>
      </AuthenticationContext>
      {/*<ReactQueryDevtools initialIsOpen={true} />*/}
    </QueryClientProvider>
  </SidebarContext>
)
