import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightIcon,
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  BuildingStorefrontIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
  Cog8ToothIcon,
  DocumentMagnifyingGlassIcon,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  FireIcon,
  InboxStackIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PencilSquareIcon,
  PlusIcon,
  PresentationChartLineIcon,
  ShareIcon,
  TrashIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import {EyeIcon, EyeSlashIcon} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';
import {
  Building, Database,
  Factory,
  FolderOpen,
  Home,
  PanelLeftClose,
  PanelLeftOpen,
  Settings,
  Truck,
  Warehouse
} from 'lucide-react'
import {EmptySheet} from '../../assets/icons/EmptySheet';

export type Icons = 'folder' | 'truck' | 'clipboard-document' | 'share' | 'gear' | 'user' | 'users' | 'logout' | 'eye' | 'eye-slash' |
  'success-circle' | 'error-circle' | 'exclamation-circle' | 'cross' | 'factory' | 'plus' | 'trash' | 'pencil-square' |
  'magnifier' | 'arrow-right' | 'arrow-left' | 'presentation-chart' | 'book-open' | 'fire' | 'document-magnifier' | 'inbox-stack' | 'arrow-rounded' | 'empty-sheet' |
  'chevron-up' | 'chevron-down' | 'check' | 'building-storefront' | 'location-pin' | 'chevron-left' | 'chevron-right' | 'arrow-down-tray' | 'calendar' | 'three-dots-horizontal' | 'three-dots-vertical' |
  'arrow-path' | 'home' | 'settings' | 'panel-close' | 'panel-open' | 'building' | 'warehouse' | 'database';

export type IconSize = 'sm' | 'md' | 'lg' | 'full';

interface IconProps {
  icon: Icons;
  size?: IconSize;
  className?: string;
  onClick?: () => void;
}

export const Icon = ({icon, onClick, size = 'md', className}: IconProps) => {

  const RenderIcon = () => {
    switch (icon) {
      case 'folder':
        return <FolderOpen />
      case 'truck':
        return <Truck />
      case 'clipboard-document':
        return <ClipboardDocumentIcon />
      case 'share':
        return <ShareIcon />
      case 'gear':
        return <Cog8ToothIcon />
      case 'user':
        return <UserIcon />
      case 'users':
        return <UsersIcon />
      case 'logout':
        return <ArrowRightOnRectangleIcon />
      case 'eye':
        return <EyeIcon />
      case 'eye-slash':
        return <EyeSlashIcon />
      case 'success-circle':
        return <CheckCircleIcon />
      case 'error-circle':
        return <XCircleIcon />
      case 'exclamation-circle':
        return <ExclamationCircleIcon />
      case 'cross':
        return <XMarkIcon stroke="white" />
      case 'factory':
        return <Factory />
      case 'plus':
        return <PlusIcon />
      case 'trash':
        return <TrashIcon />
      case 'pencil-square':
        return <PencilSquareIcon />
      case 'magnifier':
        return <MagnifyingGlassIcon />
      case 'arrow-right':
        return <ArrowRightIcon />
      case 'arrow-left':
        return <ArrowLeftIcon />
      case 'presentation-chart':
        return <PresentationChartLineIcon />
      case 'book-open':
        return <BookOpenIcon />
      case 'fire':
        return <FireIcon />
      case 'document-magnifier':
        return <DocumentMagnifyingGlassIcon />
      case 'inbox-stack':
        return <InboxStackIcon />
      case 'arrow-rounded':
        return <ArrowPathRoundedSquareIcon />
      case 'empty-sheet':
        return <EmptySheet />
      case 'chevron-up':
        return <ChevronUpIcon />
      case 'chevron-down':
        return <ChevronDownIcon />
      case 'chevron-left':
        return <ChevronLeftIcon />
      case 'chevron-right':
        return <ChevronRightIcon />
      case 'check':
        return <CheckIcon stroke="white" />
      case 'building-storefront':
        return <BuildingStorefrontIcon />
      case 'location-pin':
        return <MapPinIcon />
      case 'arrow-down-tray':
        return <ArrowDownTrayIcon />
      case 'calendar':
        return <CalendarIcon />
      case 'three-dots-horizontal':
        return <EllipsisHorizontalIcon />
      case 'three-dots-vertical':
        return <EllipsisVerticalIcon />
      case 'arrow-path':
        return <ArrowPathIcon />
      case 'home':
        return <Home />
      case "settings":
        return <Settings />
      case 'panel-close':
        return <PanelLeftClose />
      case "panel-open":
        return <PanelLeftOpen />
      case "building":
        return <Building />
      case "warehouse":
        return <Warehouse />
      case 'database':
        return <Database />
    }
  }

  const wrapperClassname = classNames(className, {
    'h-3 w-3': size === 'sm',
    'h-5 w-5': size === 'md',
    'h-8 w-8': size === 'lg',
    'h-full w-full': size === 'full'
  })

  return (
    <div className={wrapperClassname} onClick={onClick}>
      <RenderIcon />
    </div>
  )
}
