import React from 'react';
import {ClipLoader} from 'react-spinners';

interface SpinnerProps {
  size?: string | number;
}

export const Spinner = ({size = 35}: SpinnerProps) => {
  return (
    <ClipLoader size={size} color="#36d7b7" />
  )
}
