import React from 'react'
import {LogOut, PanelLeft, Settings, User} from 'lucide-react'
import {NavLink} from 'react-router-dom'
import logo from '../../assets/images/mytimber-logo.png';
import {Sheet, SheetContent, SheetTrigger} from '../../components/ui/sheet'
import {Button} from '../../components/ui/button'
import {routes, sidebarMenuItems} from '../../router/routes'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../../components/ui/dropdown-menu'
import {useAuthState} from '../../contexts/AuthenticationContext'
import Avatar from '../../components/avatar/Avatar'
import {SheetMenuItem} from './sidebar/SidebarMenuItem'

export const NavigationBar = () => {
  const {orgUser, logout} = useAuthState()

  return (
    <header
      className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b px-4 bg-background sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
      <Sheet>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="sm:hidden">
            <PanelLeft className="h-5 w-5"/>
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="sm:max-w-xs">
          <nav className="grid gap-6 text-lg font-medium">
            <NavLink to={routes.APP.DASHBOARD}>
              <img src={logo} alt="logo"/>
            </NavLink>
            {sidebarMenuItems.map((menuItem, index) =>
              <SheetMenuItem key={index} icon={menuItem.icon} title={menuItem.name} to={menuItem.url}/>
            )}
          </nav>
        </SheetContent>
      </Sheet>
      <div className="relative ml-auto grow-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex justify-center items-center cursor-pointer">
              <Avatar alt={orgUser?.name.substring(0, 1)} size="lg"/>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{orgUser?.organization.name}</DropdownMenuLabel>
            <DropdownMenuSeparator/>
            <DropdownMenuItem disabled={true}>
              <User className="mr-2 h-4 w-4" />
              <span>Profiil</span>
            </DropdownMenuItem>
            <DropdownMenuItem disabled={true}>
              <Settings className="mr-2 h-4 w-4" />
              <span>Sätted</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator/>
            <DropdownMenuItem onClick={logout}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Logi välja</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  )
}
