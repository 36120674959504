import React from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../../../assets/images/mytimber-logo.png';
import {routes, sidebarMenuItems} from '../../../router/routes';
import {Icon, Icons} from '../../../components/icons/Icon';
import logoSmall from '../../../assets/images/logo_small.png'
import {useSidebar} from '../../../contexts/SidebarContext'
import {MenuItem, SidebarMenuItem} from './SidebarMenuItem'

export interface SidebarMenuItems {
  name: string;
  url: string;
  icon: Icons;
  active?: boolean;
}

export const SidebarMenu = () => {
  const {isOpen, setIsOpen} = useSidebar()

  if (!isOpen) {
    return (
      <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
        <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
          <NavLink to={routes.APP.DASHBOARD}>
            <img src={logoSmall} alt="logo"/>
          </NavLink>
          {sidebarMenuItems.map((menuItem, index) =>
            <MenuItem key={index} icon={menuItem.icon} title={menuItem.name} to={menuItem.url}/>)
          }
        </nav>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
          <Icon icon={isOpen ? 'panel-close' : 'panel-open'} className="h-9 w-9 cursor-pointer" size="md"
                onClick={() => setIsOpen(!isOpen)}/>
        </nav>
      </aside>
    )
  } else {
    return (

      <aside className="fixed inset-y-0 left-0 z-10 hidden w-56 flex-col border-r bg-background sm:flex">
        <nav className="flex flex-col items-center justify-start gap-3 px-2 sm:py-5">
          <NavLink to={routes.APP.DASHBOARD}>
            <img src={logo} alt="logo"/>
          </NavLink>
          {sidebarMenuItems.map((menuItem, index) =>
            <SidebarMenuItem key={index} icon={menuItem.icon} title={menuItem.name} to={menuItem.url}/>)
          }
        </nav>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
          <Icon icon={isOpen ? 'panel-close' : 'panel-open'} className="h-9 w-9 cursor-pointer" size="md"
                onClick={() => setIsOpen(!isOpen)}/>
        </nav>
      </aside>
    )
  }
}
