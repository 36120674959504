import axios from 'axios';
import {firebaseAuth} from '../contexts/AuthenticationContext';
import {sleep} from '../utils/utils';
import {SaveAggregation} from '../features/waybills/aggregation/Aggregate'
import type {AxiosResponse} from 'axios';

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE'

const apiBaseUrl = process.env.REACT_APP_MYTIMBER_API_URL

const axiosClient = axios.create({
  baseURL: apiBaseUrl,
})

export const authenticatedRequest = (url: string, method: HttpMethod, body?: string) =>
  getAuthorizationToken().then(token => fetchWithAuthorization(url, token, method, body))

const fetchWithAuthorization = (url: string, authToken: string, method: HttpMethod, body?: string): Promise<AxiosResponse> => {
  return axiosClient.request({
    url,
    headers: {
      Authorization: 'Bearer ' + authToken,
      ...body && {'content-type': 'application/json'}
    },
    method,
    data: body
  })
}

export const requestWithFile = (
  url: string,
  method: HttpMethod,
  file: File,
  body?: SaveAggregation
): Promise<AxiosResponse> => {
  return getAuthorizationToken().then(token => {
    return fetchWithAuthorizationWithFile(url, token, method, file, body)
  })
}

export const fetchWithAuthorizationWithFile = async (
  url: string,
  authToken: string,
  method: HttpMethod,
  file: File,
  body?: SaveAggregation
): Promise<AxiosResponse> => {

  const formData = new FormData()
  formData.append('file', file)

  if (body) {
    formData.append('sourceIds', body.sourceIds.toString())
    formData.append('shipmentIds', body.shipmentIds.toString())
    formData.append('senderId', body.senderId)
    formData.append('senderRepresentativeId', body.senderRepresentativeId)
    formData.append('receiverId', body.receiverId)
    formData.append('receiverRepresentativeId', body.receiverRepresentativeId)
    formData.append('documentNumber', body.documentNumber)
    formData.append('documentDate', body.documentDate.toISOString())
    formData.append('additionalInfo', JSON.stringify(body.additionalInfo))
  }

  return axiosClient.request({
    url,
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'multipart/form-data'
    },
    method,
    data: formData
  })
}

export const requestWithFileDownload = (url: string, method: HttpMethod): Promise<AxiosResponse> => {
  return getAuthorizationToken().then(token => {
    return fetchFileWithAuthorizationAndDownload(url, token, method)
  })
}

export const fetchFileWithAuthorizationAndDownload = (url: string, authToken: string, method: HttpMethod): Promise<AxiosResponse> => {
  return axiosClient.request({
    url,
    method,
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
    responseType: 'blob'
  })
}

export async function getAuthorizationToken(): Promise<string> {
  await sleep(500)
  if (firebaseAuth.currentUser) {
    return firebaseAuth.currentUser.getIdToken()
  } else {
    return Promise.reject('No user logged in')
  }
}

export default axiosClient
