import React from 'react';
import {NavLink} from 'react-router-dom'
import {Icon} from '../../../components/icons/Icon';
import {cn} from '../../../lib/utils'
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from '../../../components/ui/tooltip'
import type {Icons} from '../../../components/icons/Icon';

export interface SidebarNavItemProps {
  to: string;
  title: string;
  icon: Icons;
}

export const SidebarMenuItem = ({to, title, icon}: SidebarNavItemProps) => {
  return (
    <NavLink
      to={to}
      className={({isActive}) =>
        `flex justify-start items-center gap-4 w-full p-3 lg:rounded-lg text-base group focus:outline-none ${isActive ?
          'bg-gray-100 font-normal cursor-default' : 'hover:bg-gray-100 font-normal'}`
      }
    >
      {({isActive}) => {
        return (
          <>
            <Icon icon={icon} />
            <p
              className={`${isActive ?
                'text-[#27282D]' : 'text-[#01090F]'}`}
            >
              {title}
            </p>
          </>
      )}}
    </NavLink>
  )
}

export const MenuItem = ({icon, title, to}: SidebarNavItemProps) => {
  const linkClassnames = (active: boolean) => cn('flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-foreground md:h-8 md:w-8', {
    'bg-accent text-accent-foreground bg-gray-100': active,
    'text-muted-foreground': !active
  })

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <NavLink to={to}>
            {({isActive}) => (
              <div className={linkClassnames(isActive)}>
                <Icon icon={icon}/>
                <span className="sr-only">{title}</span>
              </div>
            )}
          </NavLink>
        </TooltipTrigger>
        <TooltipContent side="right">{title}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export const SheetMenuItem = ({icon, title, to}: SidebarNavItemProps) => {

  const linkClassnames = (active: boolean) => cn('flex items-center gap-4 py-2 px-2.5 rounded-lg', {
    'flex items-center gap-4 px-2.5 text-foreground bg-gray-100': active,
    'text-muted-foreground hover:text-foreground': !active
  })

  return (
    <NavLink to={to}>
      {({isActive}) => (
        <div className={linkClassnames(isActive)}>
          <Icon icon={icon} />
          <span>{title}</span>
        </div>
      )}
    </NavLink>
  )
}
