import type {SidebarMenuItems} from '../layouts/dashboardLayout/sidebar/SidebarMenu';

export const routes = {
  ROOT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  APP: {
    DASHBOARD: '/dashboard',
    CONTRACTS: '/contract',
    WAYBILL: {
      ROOT: '/waybill',
      AGGREGATION: '/waybill/aggregation',
      DETAILS: '/waybill/:waybillId',
      CREATE: '/waybill/create'
    },
    MASS_BALANCE: '/massbalance',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    COMPANY: {
      ROOT: '/company'
    },
    SOURCES: {
      ROOT: '/source',
      LIST: '/sources',
      DETAILS: '/source/:sourceId',
    },
    DESTINATION: {
      ROOT: '/destination',
      LIST: '/destinations',
      DETAILS: '/destination/:destinationId',
    },
    WAREHOUSE: {
      ROOT: '/warehouse',
      LIST: '/warehouses',
      DETAILS: '/warehouse/:warehouseId',
      STOCK: '/warehouse/:warehouseId/stock',
      LOT: {
        ROOT: '/lot',
        VIEW: '/lot/:lotId'
      }
    },
    FILES: {
      ROOT: '/files',
      LIST: '/files',
    }
  }
}

export const sidebarMenuItems: SidebarMenuItems[] = [
  // {name: 'Töölaud', url: routes.APP.DASHBOARD, icon: 'folder'},
  {name: 'Ettevõte', url: routes.APP.COMPANY.ROOT, icon: 'building'},
  {name: 'Lähtekohad', url: routes.APP.SOURCES.LIST, icon: 'factory'},
  {name: 'Sihtkohad', url: routes.APP.DESTINATION.LIST, icon: 'database'},
  {name: 'Veoselehed', url: routes.APP.WAYBILL.ROOT, icon: 'truck'},
  {name: 'Laod', url: routes.APP.WAREHOUSE.LIST, icon: 'warehouse'},
  {name: 'Failid', url: routes.APP.FILES.LIST, icon: 'folder'},
]
