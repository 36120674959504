import React from 'react';
import {cn} from '../../lib/utils'
import {useSidebar} from '../../contexts/SidebarContext'
import {SidebarMenu} from './sidebar/SidebarMenu'
import {NavigationBar} from './NavigationBar'


export const DashboardLayout = ({children}: any) => {

  const {isOpen} = useSidebar()

  const contentClassnames = cn('flex w-full flex-col sm:gap-4 sm:py-4', {
    'sm:pl-14': !isOpen,
    'pl-56': isOpen
  })

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <SidebarMenu />
      <div className={contentClassnames}>
        <NavigationBar />
        <main className="grid w-full flex-1 items-start gap-4 px-4 pb-4">
          {children}
        </main>
      </div>
    </div>
  )
}
