import React, {ReactElement, createContext, useContext, useEffect, useState} from 'react'
import {isSidebarOpenLS, setSidebarOpenLS} from '../utils/storage'

interface SidebarContextProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const SidebarMenuContext = createContext<SidebarContextProps>({
  isOpen: false,
  setIsOpen: () => {}
})

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};

export const SidebarContext = ({children}: {children: ReactElement;}) => {
  const [isOpen, setIsOpen] = useState(isSidebarOpenLS())

  const setSidebarCollapsed = (value: boolean) => {
    setSidebarOpenLS(value)
    setIsOpen(value)
  }

  const windowWidth = useWindowWidth();
  const [prevWindowWidth, setPrevWindowWidth] = useState(windowWidth);
  const isMobile = windowWidth <= 768;
  const wasMobile = prevWindowWidth <= 768;
  // Or 1024 lg

  useEffect(() => {
    if (isMobile !== wasMobile) {
      if (isMobile) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    }

    setPrevWindowWidth(windowWidth);
  }, [windowWidth]);

  return (
    <SidebarMenuContext.Provider value={{isOpen, setIsOpen: setSidebarCollapsed}}>
      {children}
    </SidebarMenuContext.Provider>
  )
}

export const useSidebar = () => {
  return useContext(SidebarMenuContext)
}
