import React, {useEffect} from 'react';
import NProgress from 'nprogress';

function SuspenseLoader() {
    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return <></>;
}

export default SuspenseLoader;
