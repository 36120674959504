import React from 'react';
import {Spinner} from './Spinner';

interface CenteredSpinnerProps {
  size?: number;
}

export const CenteredSpinner = ({size = 55}: CenteredSpinnerProps) => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Spinner size={size} />
    </div>
  )
}
