const TOKEN_KEY = '__sidebar_collapsed__'

export const isSidebarOpenLS = (): boolean => {
  const isCollapsed = localStorage.getItem(TOKEN_KEY)
  if (!isCollapsed) {
    return true
  } else {
    return isCollapsed === 'true'
  }
}

export const setSidebarOpenLS = (value: boolean) => {
  removeSidebarOpenLS()
  return localStorage.setItem(TOKEN_KEY, JSON.stringify(value))
}

export const removeSidebarOpenLS = () => {
  return localStorage.removeItem(TOKEN_KEY)
}
